import React, { useState, useEffect } from "react";
import "./Factory.scss";
import { useParams } from "react-router-dom";
import AppChart from "../../Components/AppChart/AppChart";
import GoogleMap from "google-map-react";
import News from "../../Components/News/News";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import { useAuth } from "../../Hooks/useAuth";
import { useApi } from "../../Hooks/useApi";
import { AddAverage, ItemsForZoom } from "../../Helper";
import FreeVersion from "../FreeVersion/FreeVersion";
import * as Config from "../../Config";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import {formatHoliday, formatWorkingDay} from '../../Helper';

const Factory = () => {
  const { slug } = useParams();
  const [data, setData] = useState(null);
  const auth = useAuth();
  const Api = useApi();

  const [errorPage, setErrorPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [calendarEvents, setCalendarEvents] = useState(null);
  const [events, setEvents] = useState([]);
  const [tabSelected, setTabSelected] = useState('Footfall');
  const [tabs, setTab] = useState([]);
  const buildTabs = [];

  useEffect(() => {
    let cancel = false;
    Api.getFactory(slug, auth.info)
    .then((result) => {
        if (result && !cancel) {
          setLoading(false);
          // if(!result.data.overview || result.data.overview === null) result.data.overview = [];
          // result.data.overview[0] = ['Week', 'Open on weekends or holidays index', 'Night shift activity index', 'Overall activity index'];
          setData(result.data);
          setErrorPage(false);
          formatWorkingDay(result.data, setEvents);
          formatHoliday(result.data.country_code, setCalendarEvents);

          if(result.data) {
            if(result.data.data !== undefined) buildTabs.push('Footfall');
            if(result.data.overview !== undefined) buildTabs.push('Overview');
            if(result.data.calenderData !== undefined) buildTabs.push('Calendar');
            if(result.data.visits !== undefined) buildTabs.push('Visits');
            if(result.data.traffic !== undefined) buildTabs.push('Traffic');
            setTabSelected(buildTabs[0]);
            setTab(buildTabs);
          }
        }
      })
      .catch((e) => {
        if (!cancel) {
          setErrorPage(true);
          setLoading(false);
        }
      });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <div className="factory-page container">
      {(!errorPage && data) && (
        <>
          <Breadcrumbs item={data} prefix="factory" />
          <div className="header-page">
            <h1>{data.name}</h1>
          </div>
          <div className="description">{data.description}</div>
          <div className="wrapper-tabs">
            <div className="tabs">
              {tabs.map((item, i) => {
                return (
                  <div key={i} className={`tab${tabSelected === item ? " active" : ""}`} onClick={() => setTabSelected(item)}>
                    {item}
                  </div>
                );
              })}
            </div>

            {data.access !== 'free' ? <>
              {(data) && (
                <>
                  {tabSelected === 'Footfall' && data.data && data.data.length > 0 && (
                    <div className="wrapper-chart Footfall">
                      <div className="description"><strong>What is Footfall?</strong>Geolocation footfall data is aggregated from the workforce based on the number of mobile devices seen.<br />The weekly normalized values are represented by the blue line.</div>
                      <div className="y-axis">Activity Level</div>
                        <AppChart data={AddAverage(data.data)} format="" height="420px" zoom={true} footfall={true} />
                      <div className="x-axis">Date</div>
                    </div>
                  )}

                  {tabSelected === 'Overview' && data.overview && data.overview.length > 0 && (
                    <div className="wrapper-chart Overview">
                      <div className="description"><strong>What is Overview?</strong>Development of overall activity (yellow line - factory performance at all), nighttime activity (blue line - night shifts) and overtime activity (red line - weekend and holiday activity).</div>
                      <div className="y-axis">Activity Level</div>
                      <AppChart data={data.overview.slice(0, -1)} format="" colors={['#CB267B', '#15DFC6', '#E0BF01']} height="420px" />
                      <div className="x-axis">Week Number</div>
                    </div>
                  )}

                  {(tabSelected === 'Calendar' && calendarEvents) && (
                    <div className="wrapper-chart">
                      <div className="description"><strong>What is Calendar?</strong>Daily view which shows if the factory was active and if it was in night shift mode.</div>
                      <FullCalendar
                        plugins={[ dayGridPlugin ]}
                        initialView="dayGridMonth"
                        events={[...calendarEvents,...events]}
                        firstDay={1}
                        navLinkWeekClick={e=>console.log(e)}
                        navLinkDayClick={e=>console.log(e)}
                        eventChange={e=>console.log(e)}
                        />
                    </div>
                  )}

                  {tabSelected === 'Visits' && data.visits !== undefined && data.visits.length > 0 && (
                    <div className="wrapper-chart visits">
                      <div className="description"><strong>What is Visits?</strong>Visits activity level, including work force. Numbers and trends are indexed, based on the number of mobile signals and web platform analytics seen per day.</div>
                      <div className="y-axis">Activity Level</div>
                      <AppChart data={ItemsForZoom(data.visits)} format="" height="420px" zoom={true} colors={['#CB267B', '#15DFC6', '#E0BF01']} chartType="LineChart" />
                      <div className="x-axis">Day</div>
                    </div>
                  )}

                  {tabSelected === 'Traffic' && data.traffic && data.traffic.length > 0 && (
                    <div className="wrapper-chart Traffic">
                      <div className="description"><strong>What is Traffic?</strong></div>
                      <div className="y-axis">Activity Level</div>
                        <AppChart data={AddAverage(data.traffic)} format="" height="420px" zoom={true} traffic={true} minmax={{max:1.5,min:-0.5}} />
                      <div className="x-axis">Date</div>
                    </div>
                  )}


                </>
              )}
            </> : <>{!loading && <FreeVersion tab={tabSelected} factory={data} user={auth.info} />}</>}
          </div>

          {data.coordinates && (
            <div className="wrapper-map">
              <GoogleMap
                bootstrapURLKeys={{ key: Config.GOOGLE_KEY }}
                style={{ height: "660px", width: "100%" }}
                defaultCenter={data.coordinates}
                defaultZoom={17}
                options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
              />
            </div>
          )}
          <News category={data.category_id} />
        </>
      )}
    </div>
  );
};

export default Factory;

import React, { useState } from 'react'
import './Header.scss'
import { useLocation } from 'react-router';
import Menu from '../Menu';

const Header = () => {
    let location = useLocation();
    const [burder, setBurger] = useState(false);
    const page = location.pathname.replace('/', '').replace('/', '-page ');
    const pageClass = (!page) ? 'home-page' : page + '-page';
    document.body.className = pageClass;

    return (
        <header>
            <div className="container">
                <a href="https://www.fygen.com" className="logo">
                    <img src="/assets/images/logo.png" alt="Fygen" />
                    <span>3.3 Beta</span>
                </a>

                <Menu area="header" burder={burder} />

                <div className={`burger ${burder ? 'opened' : 'closed'}`} onClick={()=>setBurger(!burder)}>
                    <div className="bar1"></div>
                    <div className="bar2"></div>
                    <div className="bar3"></div>
                </div>


            </div>
        </header>
    )
}

export default Header
import React, { useEffect } from "react";
import "./Login.scss";
import { useAuth } from "../../Hooks/useAuth";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";
import { getLocation } from "../../Helper";

const Login = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.loginStatusChange.subscribe((data) => {
      if (data !== null) {
        var expires = (new Date(Date.now()+ 86400*1000)).toUTCString();
        document.cookie = "auth="+data+";expires="+expires+";domain=fygen.com";
        window.location.href = "/my-account";
      }
    });
    auth.successfulRegistrationPayment.subscribe((data) => {
      if (data !== null) {
        document.cookie = "auth="+data+";domain=fygen.com"
        window.location.href = "/my-account";
      }
    });
    getLocation();
  }, [auth]);

  return (
    <div className="container">
      <LoginForm />
      <RegisterForm />
      <div className="reCAPTCHA">
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        apply.
      </div>
    </div>
  );
};

export default Login;

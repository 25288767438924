import React, { useState, useEffect } from "react";
import "./Shop.scss";
import P404 from "../P404/P404";
import OrderSummary from "./Popups/OrderSummary";
import PopupCard from "./Popups/PopupCard";
import Filter from "./Elements/Filter";
import ItemRequest from "./Elements/ItemRequest";
import Item from "./Elements/Item";
import { useAuth } from "../../Hooks/useAuth";
import { sendEvent } from "../../Helper";

const Shop = ({factories, loading, errorPage, userData, filters = ['company','location']}) => {
  const [orderSummaryDisplay, setOrderSummaryDisplay] = useState(false);
  const [cardDisplay, setCardDisplay] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filteredItems, setFilteredItems] = useState([]);
  const [UserData, setUserData] = useState([]);
  const auth = useAuth();


  const handelBuyNow = (data) => {
  }

  useEffect(()=>{
    auth.GetData().then(data=>{
      if(data.data.subscription) {
        setUserData(data.data);
        data.data.subscription.map(data=>{
          if(data.categories.length > 0) {
            data.categories.map(category=>{
              const factory = factories.filter(item=>item.category === category.name);
              if(factory.length > 0){
                setFilteredItems(item=>[...item,...factory]);
              }    
            });
          } else {
            const factory = factories.filter(item=>item.id === data.factory_id);
            if(factory.length > 0){
              setFilteredItems(item=>[...item,...factory]);
            }
          }
        })
      }
    })
  },[])

  return (
    <div className="shop-page">
      {!errorPage && filteredItems ? 
        filteredItems.length > 0 ? <>
          {filteredItems.map((data,i) => {
            return <Item data={data} key={i} handelBuyNow={handelBuyNow} userData={UserData} />
          })}
        </> :<>You have no factories subscribed for monitoring yet.</>
       : (
        <>{!loading && <P404 />}</>
      )}
    </div>
  );
};

export default Shop;

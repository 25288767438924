import React, { useState } from "react";
import "./Popup.scss";
import { Link } from "react-router-dom";
import { useApi } from "../../../Hooks/useApi";
import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as Config from "../../../Config";

const OrderSummaryPopup = ({data, setCardDisplay, setOrderSummaryDisplay}) => {
    const api = useApi();
    const [error, setError] = useState(null);
    const [loadingButton, setLoadingButton] = useState(false);
    const stripe = useStripe();

    const createSubscription = () => {
        setLoadingButton(true);
        setError(false);
        api.createSubscription(data.id).then(d=>{
            stripe.confirmCardPayment(d.data)
            .then(function(result) {
                setLoadingButton(false);
                if(result.error) {
                    setError(result.error.message);
                } else {
                localStorage.setItem('item', JSON.stringify(data));
                // setCardDisplay(false);
                window.location.href = '/confirmation';
              }
            });
        
            
        }).catch(err=>{
            console.log(err);
            setError(true);
            setLoadingButton(false);
        })
    }
    return (
        <>
            <div className="popup-backdrop"></div>
            <div className="popup">
                <div className="header">
                    <div className="title">Order Summary</div>
                </div>
                <div className="content">
                    <div className="row">
                        <div>Monthly subscription</div>
                        <div className="item-name">{data.title}</div>
                    </div>

                    <div className="note">
                        Full access to all databases which you have selected above Product Flyer <Link to="" target="_blank">(PDF)</Link>
                    </div>
                    
                    <div className="row">
                        <div>Cost</div>
                        <div>€{data.cost}</div>
                    </div>

                    <div className="note n2">
                        Hint: Payment for your 3 months subscription is due before activation of your account. Subscription can be cancelled at any time with two clicks in your account settings.
                    </div>
                </div>

                    <div className="errors">
                        {error && error}
                    </div>
                <div className="footer">
                    <div className="btn cancel" onClick={()=>setOrderSummaryDisplay(false)}>Cancel</div>
                    {/* <div className="btn" onClick={()=>{setCardDisplay(true); setOrderSummaryDisplay(false)}}>addCard</div> */}
                    <button className={`btn buyNow ${loadingButton ? "loading" : ""}`} disabled={loadingButton} onClick={createSubscription}>
                    {loadingButton ? "Processing..." : "Buy Now"}
                </button>
                </div>
            </div>
        </>
    );
};

const OrderSummary = ({data, setCardDisplay, setOrderSummaryDisplay}) => {
    const stripePromise = loadStripe(Config.STRIPE_KEY);

    return (
        <Elements stripe={stripePromise}>
            <OrderSummaryPopup  data={data} setCardDisplay={setCardDisplay} setOrderSummaryDisplay={setOrderSummaryDisplay} />
        </Elements>
    );
}
export default OrderSummary;

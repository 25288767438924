import React, { useEffect, useState } from "react";
import "./News.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useApi } from "../../Hooks/useApi";

const News = ({ category }) => {
  const [items, setItems] = useState(null);
  const [slidesToShow, setSlidesToShow] = useState(4);
  const Api = useApi();

  useEffect(() => {
    let cancel = false;
    Api.getNewsByCategory(category).then((result) => {
      if (result && !cancel) {
        setItems(result.data);
      }
    });
    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    let cancel = false;
    if (!cancel) {
      if (window.innerWidth < 1100) {
        setSlidesToShow(3);
        if (window.innerWidth < 800) {
          setSlidesToShow(2);
          if (window.innerWidth < 550) {
            setSlidesToShow(1);
          }
        }
      }
    }
    return () => {
      cancel = true;
    };
  }, []);

  //     date: "Jul 22, 2020",

  const settings = {
    dots: window.innerWidth > 800 ? false : true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 2,
  };
  return (
    <Slider className="news-wrapper" {...settings}>
      {items &&
        items.map((item, i) => {
          return (
            <a key={i} href={item.url} target="_blank" className="item" rel="noopener noreferrer">
              <div className="title">{item.title}</div>
              <div className="date">{item.date}</div>
            </a>
          );
        })}
    </Slider>
  );
};

export default News;

import React, { useState, useEffect } from "react";
import "./Password.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { useAuth } from "../../Hooks/useAuth";
import * as Config from "../../Config";
import { useApi } from "../../Hooks/useApi";

const ForgotForm = () => {
  const auth = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [loadingButton, setSent] = useState(false);
  const [error, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);


  const onSubmit = (data) => {
    setErrors(false);
    setSuccess(false);
  
    setSent(true);
    auth.forgotPassword(data);

    auth.uploadSuccess.subscribe((data) => {
      if(data.data.message === "Failed") {
        setSent(false);
        data.data.message = "Something went wrong, please check the email and try again later";
        setErrors(data.data);
      } else if(data.data.message === "Success") {
        setSent(false);
        setSuccess(true);
      }
    });
  };

  return (
    <div className="forgot">
      <div className="header-page">
        <h1>Forgot Password?</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="input-wrapper">
            <Input
              name="email"
              type="email"
              reference={register({
                required: true,
                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address" },
              })}
              nonLabel={true}
              errors={errors}
              title="Email"
              placeholder="Enter your Email"
            />
          </div>

          <button className={`btn ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">Submit</button>
          <div className="success">{success && <>Email was sent!</>}</div>
          <div className="errors">{error && 
            Object.values(error).map((e,i)=>{
            return <div key={i} className="error">{e}</div>
            })}
          </div>
      </form>
    </div>
  );
};

export default ForgotForm;

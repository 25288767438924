import React, { useState, useEffect } from "react";
import "./Home.scss";
import { useApi } from "../../Hooks/useApi";
import { useAuth } from "../../Hooks/useAuth";
import { Link, useHistory } from "react-router-dom";
import P404 from "../P404/P404";
import Shop from "../Shop/Shop";

const Home = () => {
  const auth = useAuth();
  const Api = useApi();
  const history = useHistory();

  // const [data, setData] = useState(null);
  const [factories, setFactories] = useState(null);
  // const [tabSelected, setTabSelected] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [loading, setLoading] = useState(true);
  // const [version, setVersion] = useState("free");

  useEffect(() => {
    let cancel = false;
      Api.getFactoriesList()
        .then((result) => {
          if (result.data && !cancel) {
            setFactories(Object.values(result.data).flat());
            setLoading(false);
          }
        })
        .catch((e) => setErrorPage(true));

    return () => {
      cancel = true;
    };
  }, [auth.info]);

  return (
    <div className="category-page container">
      {!errorPage && !loading ? (
        <>

          {/* <div className="header-page">
            <h1>{data.name}</h1>
            <h2>{data.description}</h2>
            <div className="search">
              <input type="text" />
              <img src="/assets/images/icon-search.png" alt="search" />
            </div>
          </div> */}

          {factories && <Shop factories={factories} filters={['company','location','category']} loading={loading} errorPage={errorPage} userData={factories.subscriptions} />}
        </>
      ) : (
        <>{!loading && <P404 />}</>
      )}
    </div>
  );
};

export default Home;

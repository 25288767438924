import React, {useState} from "react";

export const Input = (props: {
  name: string;
  reference: any;
  defaultValue?: string;
  title?;
  placeholder?;
  onChange?;
  type?;
  onFocus?;
  maxLength?;
  minLength?;
  errors?;
  onClick?;
  nonLabel?;
  id?;
}) => {
  const setTrim = (e) => {
    let input = document.getElementById(props.name);
    if (input as HTMLInputElement) {
      (input as HTMLInputElement).value = e.trim();
    }
  };
  const [password, setPassword] = useState('password');
  return (
    <>
      <label className={`field ${props.name.replace(".", "_")}`} htmlFor={!props.id ? props.name : props.id}>
        {!props.nonLabel && <span className="title" dangerouslySetInnerHTML={{ __html: props.title }}></span>}
        <div>
          <input
            type={props.type !== undefined ? props.type !== 'password' ? props.type : password : "text"}
            onChange={props.onChange}
            name={props.name}
            id={!props.id ? props.name : props.id}
            defaultValue={props.defaultValue}
            ref={props.reference}
            placeholder={props.placeholder}
            onFocus={props.onFocus}
            maxLength={props.maxLength}
            minLength={props.minLength}
            onClick={props.onClick}
            autoComplete={`new-${props.name}`}
            onBlur={(e) => setTrim(e.target.value)}
          />
          {props.type === 'password' && <span className="eyePassword" onClick={()=>setPassword(password==='password' ? 'text' : 'password')}></span>}
        </div>
        {props.errors && props.errors[props.name] && props.errors[props.name].type !== undefined && (
          <>
            {props.errors[props.name].type === "required" && <span className="error">{props.title} is required</span>}
            {props.errors[props.name].type === "pattern" && <span className="error">{props.errors[props.name].message}</span>}
            {props.errors[props.name].type === "minLength" && (
              <span className="error">
                {props.title} has minimum {props.minLength} characters
              </span>
            )}
            {props.errors[props.name].type === "maxLength" && (
              <span className="error">
                {props.title} has maximum {props.maxLength} characters
              </span>
            )}
            {props.errors[props.name].type === "validate" && <span className="error">The passwords do not match</span>}
          </>
        )}
      </label>
    </>
  );
};

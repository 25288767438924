import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as Config from "../../../Config";

const Item = ({data, handelBuyNow = null, userData = null}) => {
    const [subscription, setSubscription] = useState(null);
    
    useEffect(() => {
        if(userData.subscription.filter(item=>item.categories.length > 0).length > 0) {
            const subscription = userData.subscription.filter(item=>item.categories.length > 0)[0];
            const category = subscription.categories.filter(item=>item.name === data.category)[0];
            setSubscription(subscription);
        } else if(userData.subscription.filter(item=>item.factory_id === data.id)) {
            setSubscription(userData.subscription.filter(item=>item.factory_id === data.id)[0]);
        }
    }, [data, userData])

    return <div className="item">
        <div className="header">
            <Link to={`/factory/${data.url}`} target={!handelBuyNow ? '_blank' : ''} className="name" title={data.title}>
                {data.title}</Link>
            <div className="logo">{data.logo &&<img src={Config.STORAGE_PATH + data.logo} />}</div>
        </div>
        
        <div className="content">
            {data.company && <div>Company: <span title={data.title}>#{data.company}</span></div>}
            {data.location && <div>Location: <span title={data.location}>#{data.location}</span></div>}
            {data.symbol && <div>Symbol: <span title={data.symbol}>{data.symbol}</span></div>}
            {data.cost && <div>price: €{data.cost} per month</div>}
        </div>

        {handelBuyNow && <div className="footer">
            <Link to={`/factory/${data.url}`} className="btn">View</Link>
            {userData && subscription ? 
                <div className="status">
                    <div>Status: <span className="status-active">Active</span></div>
                    {subscription.renewal_status ? 
                        <div>Renewed in: <span className="renewed">{subscription.end}</span></div>
                    :
                        <div>Expired at: <span className="expired">{subscription.end}</span></div>
                    }
                </div>
            :
                <div onClick={()=>{handelBuyNow(data);}} className="btn buyNow">Buy Now</div>
            }
        </div>}
    </div>
}

export default Item;
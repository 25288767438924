import React, { useState, useEffect } from "react";
import "./Confirmation.scss";
import { useAuth } from "../../Hooks/useAuth";
import P404 from "../P404/P404";
import { Link } from "react-router-dom";
import Loading from "./loading.svg";

const Confirmation = () => {
  const auth = useAuth();
  const [ item ] = useState(localStorage.getItem('item') ? JSON.parse(localStorage.getItem('item')) : null);
  const [accountInfo, setAccountInfo] = useState(null);


useEffect(() => {
    let cancel = false;

    auth.GetData().then((data) => {
      if (!cancel && Object.keys(data.data).length > 0) {
        setAccountInfo(data.data);
        localStorage.removeItem('selectedItem');
        localStorage.removeItem('item');
      }
    });
    return () => {
      cancel = true;
    };
  }, []);
  return (
    <>{item ? 
        <div className={`confirmation ${!accountInfo && 'loading'}`}>
        {accountInfo ? <>
            <div className="header-page">
                <h1>Thank you for your payment</h1>
                <h2>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took</h2>
            </div>

            <div className="container">
                <div>
                    <h3>Billed to</h3>
                    <div>{accountInfo.name ? accountInfo.name : ''}</div>
                    <div>{accountInfo.street ? accountInfo.street : ''} {accountInfo.street_number ? accountInfo.street_number : ''}</div>
                    <div>{accountInfo.city ? accountInfo.city : ''}</div>
                    <div>{accountInfo.country ? accountInfo.country : ''}</div>
                </div>
                <div>
                    <h3>Subscription details</h3>
                    <div><Link to={`/factory/${item.url}`} className="link">{item.title}</Link></div>
                    <div className="row"><span>Subtotal:</span> {item.cost} EUR Monthly</div>
                    <div className="border"></div>
                    <div className="row"><span>Total payment today:</span> {item.cost} EUR</div>

                </div>
            </div>
            <div className="footer">
                <Link to="/my-account" className="btn">My Account</Link>
            </div>
            </> : <><img src={Loading} alt="Loading" /></>}
        </div>
    : <P404 />}</>
  );
};

export default Confirmation;

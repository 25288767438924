import React, { useState, useEffect } from "react";
import "./MyAccount.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";

import { useApi } from "../../Hooks/useApi";

const MyAccount = () => {
  const auth = useAuth();
  const Api = useApi();
  const { register, handleSubmit, errors } = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [subscriptions, setSubscriptions] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    let cancel = false;

    auth.GetData().then((data) => {
      if (Object.keys(data.data).length > 0) {
        !cancel && setAccountInfo(data.data);
      } else {
        localStorage.removeItem("auth");
        window.location.href = "/login";
      }
    });
    Api.getSubscriptions().then((data) => !cancel && setSubscriptions(data.data));

    return () => {
      cancel = true;
    };
  }, []);

  const handleForm = async (data) => {
    setLoadingButton(true);
    auth.updateProfile(data);
    setError(false);

    auth.onUpdateSuccess.subscribe((x) => {
      setSuccess(true);
      setLoadingButton(false);

      setTimeout(() => {
        setSuccess(false);
      }, 1000);
    });

    auth.onError.subscribe((x) => {
      if (!x.status) {
        setLoadingButton(false);
        setError(true);
        setSuccess(false);
      }
    });
  };

  const logout = () => {
    localStorage.removeItem("auth");
    window.location.href = "/login";
  };
  return (
    <>
      {accountInfo && Object.keys(accountInfo).length > 0 && (
        <div className="container">
          <div className="header-page">
            <h1>My Account</h1>
          </div>
          <h3>Your Subscriptions</h3>
          <div className="subscriptions">
            <div className="table">
              <div className="table-row">
                <div>Product</div>
                <div>Cost</div>
                <div>Start Date</div>
                <div>End Date</div>
                <div>Renewal Date</div>
                <div>Status</div>
                <div>Options</div>
              </div>
              {subscriptions && subscriptions.length > 0 ? (
                subscriptions.map((data, index) => {
                  return (
                    <div className="table-row" key={index}>
                      <div>
                        {data.plan && data.plan.name}
                        {data.factory && <Link to={`/factory/${data.factory.slug}`}>{data.factory.name}</Link>}
                      </div>
                      <div>€{data.price}</div>
                      <div>{data.start}</div>
                      <div>{data.end}</div>
                      <div>{data.renewal_status ? data.end : "Canceled"}</div>
                      <div>{data.status === 0 ? "Pending" : data.status === 1 ? "Active" : "Deactivate"}</div>
                      <div>
                        <Link to={`/my-account/subscription/${data.id}x${data.factory_id !== null ? data.factory_id : 0}`} className="btn">Info</Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row">No subcription exist</div>
              )}
            </div>
          </div>

          <form onSubmit={handleSubmit(handleForm)} noValidate className="register">
            <div className="input-wrapper">
              <h3>Your contact information</h3>
              <div className="inputRow">
                <Input
                  name="name"
                  type="text"
                  reference={register({ required: true })}
                  nonLabel={true}
                  errors={errors}
                  title="Name"
                  placeholder="Name *"
                  defaultValue={accountInfo ? accountInfo.name : ""}
                />
                <Input
                  name="company"
                  type="text"
                  reference={register({ required: false })}
                  nonLabel={true}
                  errors={errors}
                  title="Company"
                  placeholder="Company"
                  defaultValue={accountInfo ? accountInfo.company : ""}
                />
                <Input
                  name="tax_id"
                  type="text"
                  reference={register({ required: false })}
                  nonLabel={true}
                  errors={errors}
                  title="Tax ident"
                  placeholder="Tax ident"
                  defaultValue={accountInfo ? accountInfo.tax_id : ""}
                />
              </div>
              <div className="inputRow">
                <Input
                  name="street"
                  type="text"
                  reference={register({ required: true })}
                  nonLabel={true}
                  errors={errors}
                  title="Street *"
                  placeholder="Street *"
                  defaultValue={accountInfo ? accountInfo.street : ""}
                />
                <Input
                  name="street_number"
                  type="text"
                  reference={register({ required: true })}
                  nonLabel={true}
                  errors={errors}
                  title="No"
                  placeholder="No *"
                  defaultValue={accountInfo ? accountInfo.street_number : ""}
                />
              </div>
              <div className="inputRow">
                <Input
                  name="zip"
                  type="text"
                  reference={register({ required: true })}
                  nonLabel={true}
                  errors={errors}
                  title="Zip"
                  placeholder="Zip *"
                  defaultValue={accountInfo ? accountInfo.zip : ""}
                />
                <Input
                  name="city"
                  type="text"
                  reference={register({ required: true })}
                  nonLabel={true}
                  errors={errors}
                  title="City"
                  placeholder="City *"
                  defaultValue={accountInfo ? accountInfo.city : ""}
                />
                <Input
                  name="country"
                  type="text"
                  reference={register({ required: true })}
                  nonLabel={true}
                  errors={errors}
                  title="Country"
                  placeholder="Country *"
                  defaultValue={accountInfo ? accountInfo.country : ""}
                />
              </div>
              <div className="inputRow">
                <Input
                  name="phone"
                  type="text"
                  reference={register({ required: false })}
                  nonLabel={true}
                  errors={errors}
                  title="Phone"
                  placeholder="Phone"
                  defaultValue={accountInfo ? accountInfo.phone : ""}
                />
                <Input
                  name="password"
                  type="password"
                  reference={register({ required: false })}
                  nonLabel={true}
                  errors={errors}
                  title="Password"
                  placeholder="Keep empty unless you would like to change your password"
                />
              </div>
            </div>
            <div>
              <button className={`btn ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">
                Save
              </button>
              <div className="error">{error && <>Error Message</>}</div>
              <div className="success">{success && <>Successfully updated</>}</div>
            </div>
          </form>
          <div onClick={logout} className="link">
            Logout
          </div>
        </div>
      )}
    </>
  );
};

export default MyAccount;

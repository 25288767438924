import React, { createContext, useState } from "react";
import { render } from "react-dom";
import { BrowserRouter, Switch, Route, Redirect, withRouter } from "react-router-dom";
import Header from "./Components/Header/Header";
import Footer from "./Components/Footer/Footer";
import ScrollIntoView from "./Hooks/ScrollIntoView";
import Category from "./Pages/Category/Category";
import Factory from "./Pages/Factory/Factory";
import Area from "./Pages/Area/Area";
import Login from "./Pages/Login/Login";
import MyAccount from "./Pages/MyAccount/MyAccount";
import MyInvoices from "./Pages/MyInvoices/MyInvoices";
import Subscription from "./Pages/Subscription/Subscription";
import Confirmation from "./Pages/Confirmation/Confirmation";
import Password from "./Pages/Password/Password";
import Home from "./Pages/Home/Home";
import * as Config from "./Config";
import ReactGA from "react-ga4";

export const AppContext = createContext(null);

export default function App() {
  const [appContext, setAppContext] = useState(null);

  // const auth = useAuth();

  // useEffect(() => {
  //   let isCancelledSettings = false;

  //   fetchSingleton('Settings').then(pages => {
  //     if (!isCancelledSettings) {
  //       setAppContext(pages);
  //     }
  //   });

  //   return () => {
  //     isCancelledSettings = true;
  //   };
  // }, [])
  if(Config.GA_KEY) {
    ReactGA.initialize(Config.GA_KEY);
    ReactGA.send("pageview");
  }
  
  return (
    <BrowserRouter>
      <AppContext.Provider value={appContext}>
        <ScrollIntoView>
          <Header />
          <div className="main-container">
            <Switch>
              {/* <Route exact path="/">
                <Redirect to="/power-transformers" />
              </Route> */}
              <Route exact path="/" component={Home} />

              <Route
                exact
                path="/login"
                render={(data) => {
                  if (localStorage.getItem("auth") || localStorage.getItem("auth") !== null) {
                    data.history.push("/my-account");
                  } else {
                    return <Login />;
                  }
                }}
              ></Route>

              <Route path="/resetPassword" render={()=><Password Page="resetPassword" />} />
              <Route path="/forgotPassword" render={()=><Password Page="forgotPassword" />}  />

              <Route path="/my-account/subscription/:id" component={Subscription} />
              <Route path="/my-account/my-invoices" component={MyInvoices} />
              <Route path="/confirmation" component={Confirmation} />
              {localStorage.getItem("auth") || localStorage.getItem("auth") !== null ? (
                <Route path="/my-account" component={MyAccount} />
              ) : (
                <Route
                  path="/my-account"
                  render={(data) => {
                    data.history.push("/login");
                  }}
                ></Route>
              )}

              <Route path="/factory/:slug" component={Factory} />
              <Route path="/:category/:tab/:area" component={Area} />
              <Route path="/:category/:tab" component={Category} />
              <Route path="/:category" component={Category} />

              <Route path="/404" component={() => <div>404 Not found </div>} />
              {/* <Route path="/:slug" component={Page} /> */}
            </Switch>
          </div>
          <Footer />
        </ScrollIntoView>
      </AppContext.Provider>
    </BrowserRouter>
  );
}
withRouter(App);

// render(
//   <BrowserRouter>
//     {/* <AppWithRouter /> */}
//   </BrowserRouter>,
//   document.getElementById("root")
// );

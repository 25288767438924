import React from "react";
import "./Breadcrumbs.scss";
import { Link } from "react-router-dom";

const Breadcrumbs = ({ item, prefix, category = null, chart = null }) => {

  return (
    <>
      {item && (
        <ul className="breadcrumbs">
          <li>
            <Link to="/">Home</Link>
          </li>
          {item.category && (
            <li>
              <Link to={`/${item.category.slug}`}>{item.category.name}</Link>
            </li>
          )}
          {category && (
            <li>
              <Link to={`/${category}`}>{category.replace("-"," ")}</Link>
            </li>
          )}
          {chart && (
            <li>
              <Link to={`/${category}/${chart.slug}`}>{chart.tab}</Link>
            </li>
          )}
          {(prefix && prefix === 'factory') && (
            <li>
              <Link to={`/${item.category.slug}/factory_activity_level`}>Factory Activity Level</Link>
            </li>
          )}
          {item.name && (
            <li>
              {item.name}
            </li>
          )}
        </ul>
      )}
    </>
  );
};

export default Breadcrumbs;

import React,{useState} from "react";
import "./FreeVersion.scss";
import OrderSummary from "../Shop/Popups/OrderSummary";

const FreeVersion = (props) => {
  const factory = props.factory;
  const [orderSummaryDisplay, setOrderSummaryDisplay] = useState(false);
  const item = {"title":factory.name,"url":factory.slug,"company":factory.company,"location":factory.location,"logo":factory.logo,"id":factory.id,"cost":factory.cost};

  const redirectToLogin = () => {
    if(!props.user) {
      localStorage.setItem('selectedItem', JSON.stringify(item));
      window.location.href = "/login";
    } else {
      setOrderSummaryDisplay(true);
    }

  }
  return (
    <>
      <div className="error-page">
        {props.tab && <div className="wrapper">
          {props.tab === 'Footfall' && <img src="/assets/images/Footfall.png" />}
          {props.tab === 'Overview' && <img src="/assets/images/Overview.png" />}
          {props.tab === 'Calendar' && <img src="/assets/images/Calendar.png" />}
          {props.tab === 'Visits' && <img src="/assets/images/Visits.png" />}
        </div>}
        {factory.listed === 1 ? <>
          <h1>Free Version</h1>
          <h2>For this area you need Pro version, <br /><span className="link" onClick={redirectToLogin}>Get Pro version!</span></h2>
        </> : <>
          <h1>Limited location</h1>
          <h2>To access to this location you need to contact your account manager</h2>
        </> }
      </div>
      {(orderSummaryDisplay) && <OrderSummary data={item} setOrderSummaryDisplay={setOrderSummaryDisplay} setCardDisplay={false} />}
    </>
  );
}

export default FreeVersion;

import React, { useState } from "react";
import "./Password.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { useAuth } from "../../Hooks/useAuth";
import { useLocation } from "react-router-dom";

const ResetForm = () => {
  const auth = useAuth();
  const { register, handleSubmit, errors, getValues } = useForm();
  const [loadingButton, setSent] = useState(false);
  const [error, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);

  const search = useLocation().search;
  const params = new URLSearchParams(search);
  const token = params.get('token');
  const email = params.get('email');

  const onSubmit = (data) => {
    setErrors(false);
    setSuccess(false);
    data.token = token;
    data.email = email;
    setSent(true);
    auth.resetPassword(data);

    auth.uploadSuccess.subscribe((data) => {
      setSent(false);
      setSuccess(true);
      setTimeout(()=>{
        window.location.href = "/login";
      },2000);
    });

    auth.onError.subscribe((err) => {
      console.log(err.errors);
      setSent(false);
      setErrors(err.errors);
    });
  };

  return (
    <div className="reset">
      <div className="header-page">
        <h1>Reset Password</h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="input-wrapper">
          <Input
            name="password"
            type="password"
            id="login_password"
            minLength="8"
            reference={register({ 
              required: true,
              minLength: 8,
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/,
                message: "The password most include one uppercase, one lowercase and one number",
              }
            })}
            errors={errors}
            title="New Password"
            placeholder="Password"
          />
          <Input
            name="password_confirmation"
            type="password"
            id="login_password"
            minLength="8"
            reference={register({
              required: true,
              minLength: 8,
              pattern: {
                value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/,
                message: "The password most include one uppercase, one lowercase and one number",
              },
              validate: value => value === getValues()['password'] || "The passwords do not match"
            })}
            errors={errors}
            title="Conform New Password"
            placeholder="Password"
          />
        </div>
        <div>
          <button className={`btn ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">
            Reset
          </button>
          <div className="success">{success && <>Successfully updated, you will be redirect to the login page</>}</div>
          <div className="errors">{error && 
            Object.values(error).map((e,i)=>{
            return <div key={i} className="error">{e}</div>
            })}
          </div>
        </div>
      </form>
    </div>
  );
};

export default ResetForm;

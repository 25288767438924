import React, { useState, useEffect } from "react";
import "./MyInvoices.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { Link } from "react-router-dom";
import { useAuth } from "../../Hooks/useAuth";

import { useApi } from "../../Hooks/useApi";

const MyInvoices = () => {
  const auth = useAuth();
  const Api = useApi();
  const { register, handleSubmit, errors } = useForm();
  const [loading, setLoading] = useState(true);
  const [invoices, setInvoices] = useState(null);
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    // let cancel = false;

    auth.GetData().then((data) => {
      if (Object.keys(data.data).length > 0) {
        setAccountInfo(data.data);
      } else {
        localStorage.removeItem("auth");
        window.location.href = "/login";
      }
    });
    Api.getInvoices().then((data) => {
      setInvoices(data.data);
      setLoading(false);
    });

    return () => {
      // cancel = true;
    };
  }, []);

  const logout = () => {
    localStorage.removeItem("auth");
    window.location.href = "/login";
  };
  return (
    <>
      {accountInfo && Object.keys(accountInfo).length > 0 && (
        <div className="container">
          <div className="header-page">
            <h1>My Invoices</h1>
          </div>
          <div className="subscriptions">
            <div className="table">
              <div className="table-row">
                <div>Type</div>
                <div>Number</div>
                <div>Cost</div>
                <div>Date</div>
                <div>PDF</div>
              </div>
              {invoices && invoices.length > 0 ? (
                invoices.map((data, index) => {
                  return (
                    <div className="table-row" key={index}>
                      <div>{data.object}</div>
                      <div>{data.number}</div>
                      <div>€{data.amount_paid / 100}</div>
                      <div>{new Date(data.created * 1000).toDateString()}</div>
                      <div>
                        <a href={data.invoice_pdf}>Download</a>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="table-row loading">
                  <div>{loading ? "loading..." : "No Invoices exist"}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MyInvoices;

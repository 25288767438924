import { useState, useEffect } from "react";
import * as Config from "../Config";
import axios from "axios";
// import { LoginRequest, RegistrationRequest, ForgotPasswordRequest, UpdatePasswordRequest, uploadDocumentsRequest } from "./models";
import { EventEmitter } from "./EventEmitter";

export const useAuth = () => {
  const onLoginSuccess = new EventEmitter("login_success");
  const onUpdateSuccess = new EventEmitter("update_success");
  const loginStatusChange = new EventEmitter("login_status_change");
  const registerStatusChange = new EventEmitter("register_status_change");
  const successfulRegistrationPayment = new EventEmitter("successful_registration_payment");
  const [info, setInfo] = useState(localStorage.getItem("auth") ? localStorage.getItem("auth") : null);
  const onError = new EventEmitter("login_error");
  const uploadSuccess = new EventEmitter("upload_success");

  useEffect(() => {
    let cancel = false;

    if (!cancel && info) {
      const time = new Date().getTime();
      localStorage.setItem("auth", info);
      const t = new Date(new Date(time).getTime() + 60 * 60 * 24 * 1000).getTime().toString();
      localStorage.setItem("t", t);
    }

    return () => {
      cancel = true;
    };
  }, [info]);

  const checkLogin = () => {
    if (info !== null) {
      window.location.href = "/my-account";
    } else {
      // window.location.href = "/login";
    }
    return false;
  };

  const signOut = () => {
    setInfo(null);
    localStorage.removeItem("auth");
    localStorage.removeItem("t");
    loginStatusChange.emit(null);
    window.location.href = "/";
  };

  const signIn = (loginRequest) => {
    const dataLocation = localStorage.getItem('dataLocation') ? localStorage.getItem('dataLocation') : null;
    axios
      .post(`${Config.API_HOST}/api/user/login`, {...loginRequest,dataLocation})
      .then((data) => {
        if (!data.data.error) {
          setInfo(data.data.api_token);
          loginStatusChange.emit(data.data.api_token);
        } else {
          onError.emit(data.data.error);
        }

        return data;
      })
      .catch((err) => onError.emit(err));
  };

  const signUp = (registrationRequest) => {
    const dataLocation = localStorage.getItem('dataLocation') ? localStorage.getItem('dataLocation') : null;
    axios
      .post(`${Config.API_HOST}/api/user/register`, {...registrationRequest,dataLocation})
      .then((x) => {
        if (x.data.user.api_token !== undefined) {
          setInfo(x.data.user.api_token);
          registerStatusChange.emit(x.data);
        } else {
          onError.emit(x.data);
        }
        return x;
      })
      .catch((err) => { if(err.response !== undefined) onError.emit(err.response) });
  };

  const updateProfile = (data) => {
    data.token = info;
    axios
      .post(`${Config.API_HOST}/api/account/update`, data)
      .then((x) => {
        onUpdateSuccess.emit(x.data);
        return x;
      })
      .catch((e) => console.log(e));
  };


  const forgotPassword = (data) => {
      axios.post(`${Config.API_HOST}/api/password/forget`, data).then(x => {
        uploadSuccess.emit(x);
      })
      .catch(e => onError.emit(e.response.data))
  }

  const resetPassword = (data) => {
      axios.post(`${Config.API_HOST}/api/password/reset`, data).then(x => {
        uploadSuccess.emit(x);
      })
      .catch(e => onError.emit(e.response.data))
  }

  const GetData = () => {
    return axios.post(`${Config.API_HOST}/api/getProfile`, { token: info });
  };
  
  const UpdateCardAndSubscribe = (data) => {
    return axios.post(`${Config.API_HOST}/api/ucas`, { ...data, token: info });
  }
  
  return {
    signIn,
    signUp,
    onLoginSuccess,
    onUpdateSuccess,
    loginStatusChange,
    registerStatusChange,
    successfulRegistrationPayment,
    info,
    onError,
    uploadSuccess,
    checkLogin,
    signOut,
    updateProfile,
    forgotPassword,
    resetPassword,
    GetData,
    UpdateCardAndSubscribe
  };
};

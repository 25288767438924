import React, { useState } from "react";
import "./Login.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { useAuth } from "../../Hooks/useAuth";
import { Link } from "react-router-dom";

const LoginForm = () => {
  const auth = useAuth();
  const { register, handleSubmit, errors } = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [error, setError] = useState(null);

  const onLoginSubmit = (data) => {
    setLoadingButton(true);
    auth.signIn(data);
    auth.onError.subscribe((err) => {
      setLoadingButton(false);
      setError(err);
    });
  };

  return (
    <div className="login">
      <div className="header-page">
        <h1>Already a customer? Login here</h1>
        <h2>Login here if you already have a username (which is the email ID you provided) and a password.</h2>
      </div>
      <form onSubmit={handleSubmit(onLoginSubmit)} noValidate>
        <div className="input-wrapper">
          <Input
            name="email"
            id="login_email"
            type="email"
            reference={register({
              required: true,
              pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address" },
            })}
            errors={errors}
            title="Email"
            placeholder="Email"
          />
          <Input
            name="password"
            type="password"
            id="login_password"
            reference={register({ required: true })}
            errors={errors}
            title="Password"
            placeholder="Password"
          />
        </div>
        <div>
          <button className={`btn ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">
            {loadingButton ? "PROCESSING..." : "Login"}
          </button>
          <div className="error">{error && <>{error}</>}</div>
        </div>
        <Link className="forgotPassword" to='/forgotPassword'>Forgot Password?</Link>
      </form>
    </div>
  );
};

export default LoginForm;

import axios from "axios";
import * as Config from "../Config";

export const useApi = () => {
  const getToken = localStorage.getItem("auth") || null;

  const getCategory = (category, tab?) => {
    return axios.post(`${Config.API_HOST}/api/getCategory`, { category: category, token: getToken, tab:tab });
  };

  const getCategoryData = (category, tab?) => {
    return axios.post(`${Config.API_HOST}/api/getCategoryData`, { category: category, token: getToken, tab:tab });
  };

  const getFactoriesByCategory = (category) => {
    return axios.post(`${Config.API_HOST}/api/getFactoriesByCategory/${category}`, { token: getToken });
  };

  const getFactory = (factory, v) => {
    return axios.post(`${Config.API_HOST}/api/getFactory/${factory}`, { token: getToken });
  };

  const getSubscriptions = () => {
    return axios.post(`${Config.API_HOST}/api/getSubscriptions`, { token: getToken });
  };

  const getSubscription = (id) => {
    return axios.post(`${Config.API_HOST}/api/getSubscription/${id}`, { token: getToken });
  };

  const getCategories = () => {
    return axios.post(`${Config.API_HOST}/api/getCategories`, { token: getToken });
  };

  const getInvoices = () => {
    return axios.post(`${Config.API_HOST}/api/getInvoices`, { token: getToken });
  };

  const cancelSubscription = (id) => {
    return axios.post(`${Config.API_HOST}/api/cancelSubscription`, { token: getToken, subscription_id: id });
  };

  const getPlans = () => {
    return axios.get(`${Config.API_HOST}/api/getPlans`);
  };

  const getNewsByCategory = (category) => {
    return axios.get(`${Config.API_HOST}/api/getNewsByCategory/${category}`);
  };

  const getMenu = (area) => {
    return axios.get(`${Config.API_HOST}/api/getMenu/${area}`);
  };

  const getFactoriesList = () => {
    return axios.get(`${Config.API_HOST}/api/getFactoriesList`);
  };




  const addCard = (card_token) => {
    return axios.post(`${Config.API_HOST}/api/addCard`, { token: getToken, card_token: card_token });
  };

  const createSubscription = (subscription_id) => {
    return axios.post(`${Config.API_HOST}/api/createSubscription`, { token: getToken, productId: subscription_id });
  };


  return {
    getCategory,
    getCategoryData,
    getFactoriesByCategory,
    getFactory,
    getNewsByCategory,
    getMenu,
    getPlans,
    getSubscriptions,
    getSubscription,
    getCategories,
    getInvoices,
    cancelSubscription,
    getFactoriesList,
    addCard,
    createSubscription,
  };
};

import React from "react";
import ReactDOM from "react-dom";
import "./App.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

ReactDOM.render(
  <GoogleReCaptchaProvider reCaptchaKey="6LdwqzwaAAAAAFs_P_a1WauG51xsvqv2AcskuLpo">
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </GoogleReCaptchaProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useApi } from "../Hooks/useApi";

const MenuContent = ({ data }) => {
  return (
    <>
      {data.map((item, i) => {
        const currentPage = window.location.pathname;
        return (
          <li key={i}>
            {item.target === "_self" ? (
              <Link to={item.url} className={`${currentPage === item.url ? "current" : ""}`}>
                {item.title}
              </Link>
            ) : (
              <a href={item.url} className={`${currentPage === item.url ? "current" : ""}`}>
                {item.title}
              </a>
            )}

            {item.children.length > 0 && (
              <ul>
                {item.children.map((cItem, cI) => {
                  return (
                    <li key={cI}>
                      {cItem.target === "_self" ? (
                        <Link to={cItem.url} className={`${currentPage === cItem.url ? "current" : ""}`}>
                          {cItem.title}
                        </Link>
                      ) : (
                        <a href={cItem.url} className={`${currentPage === cItem.url ? "current" : ""}`}>
                          {cItem.title}
                        </a>
                      )}
                    </li>
                  );
                })}
              </ul>
            )}
          </li>
        );
      })}
    </>
  );
};

const Menu = (props) => {
  const [mainMenu, setMeinMenu] = useState(null);
  const [sideMenu, setSideMenu] = useState(null);
  const api = useApi();

  useEffect(() => {
    let cancel = false;
    api.getMenu("header").then((result) => {
      if (result.data && !cancel) {
        setMeinMenu(result.data.header);
        setSideMenu(localStorage.getItem("auth") !== null ? result.data.member : result.data.guest);
      }
    });
    return () => {
      cancel = true;
    };
  }, []);

  // useEffect(() => {
  //     let cancel = false;
  //     if(localStorage.getItem('auth') && !cancel) {
  //         setSideMenu(menuMember);
  //     }
  //     return () => {
  //         cancel = true;
  //     }
  // }, [localStorage.getItem('auth')])

  return (
    <ul className={`menu ${props.burder ? "opened" : "closed"}`}>
      {mainMenu && <MenuContent data={mainMenu} />}
      {sideMenu && <MenuContent data={sideMenu} />}
    </ul>
  );
};

export default Menu;

import React, { useState, useEffect } from "react";
import "./Area.scss";
import { useParams } from "react-router-dom";
import AppChart from "../../Components/AppChart/AppChart";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import { useApi } from "../../Hooks/useApi";
import { ConverStringToNumber } from "../../Helper";

const Area = () => {
  const { category, tab, area } = useParams();
  const [data, setData] = useState(null);
  const [chart, setChart] = useState(null);
  const [version, setVersion] = useState("free");
  const Api = useApi();

  useEffect(() => {
    let cancel = false;
    Api.getCategory(category).then((result) => {
      if (result && !cancel) {
        const chart = result.data.charts.filter((e) => e.slug === tab)[0];
        setChart(chart);
        setVersion(result.data.version);
        const newData = chart.areas.filter((e) => e.slug === area)[0];
        if (newData) {
          const newContent = [];
          Object.keys(newData.data).map((i) => {
            newContent.push(newData.data[i]);
          });
          newData.data = newContent;
          setData(newData);
        } else {
        }
      }
    });
    return () => {
      cancel = true;
    };
  }, []);

  return (
    <div className="factory-page container">
      {data && (
        <>
          <Breadcrumbs item={data} category={category} chart={chart} prefix="area" />
          <div className="header-page">
            <h1>{data.name}</h1>
          </div>
          <div className="description">{data.description}</div>
          <div className="wrapper-chart">
            <AppChart data={ConverStringToNumber(data.data)} format={data.symbol} height="420px" hideYear={chart.template === 2 || version} />
          </div>

          <div className="wrapper-map">
            {/* {data.location && (
              <GoogleMap
                bootstrapURLKeys={{ key: "AIzaSyC2iBVmsLnUL7mSjTIPiz2RH8EwEoK8zZs" }}
                style={{ height: "660px", width: "100%" }}
                defaultCenter={data.location}
                defaultZoom={17}
                options={(map) => ({ mapTypeId: map.MapTypeId.SATELLITE })}
              />
            )} */}
          </div>
          {/* <News category={data.category_id} /> */}
        </>
      )}
    </div>
  );
};

export default Area;

import Holidays from 'date-holidays';
import ReactGA from "react-ga4";
import axios from "axios";

export const SymbolMaping = {
    'Germany': '€',
    'EU': '$',
    'Canada': 'C$',
    'USA': '$',
    'Japan': 'Index:'
}

// Considered to be remove
export const ConverValueToIndex = (data, zoom?, formating?) => {
  const exp = [];
  data.map((area, i)=>{
    if(i>0) {
      area.map((item, index)=>{
        if(index > 0) {
          const first = data[1][index];
          if(item !== null){
            const number = ((item/first)*100);
            item = Math.round(number);
          }
          exp[i].push(item);
        } else {
          let itemForZoom = new Date(item);
          const newDate = zoom ? itemForZoom : formating ? new Date(item).toDateString() : item;
          exp.push([newDate]);
        }
      })
    } else {
      exp.push(area);
    }
  });
  return exp;
}

export const ItemsForZoom = (data) => {
  const exp = [];
  data.map((area, i)=>{
    if(i>0) {
      area[0] = new Date(area[0]);
      exp.push(area);
    } else {
      exp.push(area);
    }
  })
  return exp;
}

export const ConverStringToNumber = (data) => {
  const exp = [];
  data.map((area, i)=>{
    if(i>0) {
      area.map((item, index)=>{
        if(index > 0) {
          if(item !== null){
            item = Math.round(item);
          }
          exp[i].push(item);
        } else {
          const newDate = item;
          exp.push([newDate]);
        }
      })
    } else {
      exp.push(area);
    }
  });
  return exp;
}


const CalculateAverage = (data) => {
  const arr = [];
  let total = 0;
  data.map(item=>{
    if(!isNaN(item[1])) {
      total = total + item[1];
      arr.push(item[1]);
    }
  })
  return (total / arr.length);
}

export const AddAverage = data => {
  const arr = [];
  data.map((item,i)=>{
    if(i > 0){
      item[0] = new Date(item[0]);
      item[1] = Number(item[1]);
      // item[2] = (i > 6 ? CalculateAverage(data.slice(i-7, i)) : null);
    } else {
      // item[2] = '7-day average';
    }
    arr.push(item);
  })
  return data;
}

// For Calendar Events
export const formatHoliday = (country, setCalendarEvents) => {
  const hd = new Holidays(country);
  const year = new Date().getFullYear();
  if(hd.getHolidays(year).length > 0) {
    var events_last_year = hd.getHolidays(year-1);
    var events_this_year = hd.getHolidays(year);
    const events = events_last_year.concat(events_this_year);
    events.map(value=>{
      value['title'] = value.name;
      value['allDay'] = true;
      value['color'] = '#e0bf00';
      delete value.name;
      delete value.date;
      delete value.rule;
    });
    setCalendarEvents(events);
  }
}
// For Calendar Events
export const formatWorkingDay = (data, setEvents) => {
  const events = [];
  if(data && data.calenderData){
    Object.values(data.calenderData).map((value, i)=>{
      if(i > 0 && value[Object.keys(value)[1]] > 20) {
        if(value['night_shift']) {
          events.push({title: "Night Shift", date: value['date'], color: '#484848'})
        }
        
        events.push({title: "Factory Active", date: value['date'], color: '#15dfc6'})
      }
    })
    setEvents(events);
  }
}

export const dateInPast = function(firstDate, secondDate = new Date()) {
  if (firstDate.setHours(0, 0, 0, 0) <= secondDate.setHours(0, 0, 0, 0)) {
    return true;
  }
  return false;
};

export const sendEvent = (event, data) => {
  ReactGA.event(event, data);
 
  // {
  //   category: "your category",
  //   action: "your action",
  //   label: "your label", // optional
  //   value: 99, // optional, must be a number
  //   nonInteraction: true, // optional, true/false
  //   transport: "xhr", // optional, beacon/xhr/image
  // }
}

export const getLocation = () => {
  return axios.post('https://api.infoip.io').then(item=>{
    localStorage.setItem('dataLocation', JSON.stringify(item.data));
  });
}
import React, { useEffect, useState } from "react";
import "./Login.scss";
import { useForm } from "react-hook-form";
import { Input } from "../../Components/Input";
import { useAuth } from "../../Hooks/useAuth";

import { useApi } from "../../Hooks/useApi";

const CheckoutForm = () => {
  const auth = useAuth();
  const Api = useApi();
  const { register, handleSubmit, errors } = useForm();
  const [loadingButton, setLoadingButton] = useState(false);
  const [displayError, setDisplayError] = useState(null);
  

  const handleForm = async (data) => {
    setLoadingButton(true);
    setDisplayError(null);
  
    const customerDetails = {};
    if(data.phone) customerDetails['phone'] = data.phone;
    if(data.street || data.city || data.zip) customerDetails['address'] = [];
    if(data.street) customerDetails['address']['line1'] = data.street;
    if(data.city) customerDetails['address']['city'] = data.city;
    if(data.zip) customerDetails['address']['zip'] = data.zip;

    auth.signUp(data);

    auth.registerStatusChange.subscribe((x)=>{
      auth.successfulRegistrationPayment.emit(x.user.api_token);
    })

  
    auth.onError.subscribe((x) => {
      setLoadingButton(false);
      if(x.data.errors) {
        setDisplayError(x.data.errors);
      }
      if(x.data.message) {
        setDisplayError([x.data.message]);
      }
    });
  };

  return (
    <>
      <div className="header-page">
        <h1>New customer? Get Pro Version</h1>
        <h2>
          To see all data and to keep informed on all updated for the duration of one year, just order our Pro version with a few clicks. At first,
          make sure to select the product category or categories which you need. The access to all up-to-date data will be possible immediately after
          your payment was done successfully.
        </h2>
      </div>
      <form onSubmit={handleSubmit(handleForm)} noValidate>
        <div className="input-wrapper">

          <h3>Your contact information</h3>
          <div className="inputRow">
            <Input
              name="email"
              type="email"
              reference={register({
                required: true,
                pattern: { value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: "invalid email address" },
              })}
              nonLabel={true}
              errors={errors}
              title="Email"
              placeholder="Enter your Email"
            />
            <Input
              name="password"
              type="password"
              minLength="8"
              reference={register({
                required: true,
                minLength: 8,
                pattern: {
                  value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9]).*$/,
                  message: "The password most include one uppercase, one lowercase and one number",
                },
              })}
              nonLabel={true}
              errors={errors}
              title="Password"
              placeholder="Set your Password"
            />
          </div>
          <h3>Your billing address</h3>
          <div className="inputRow">
            <Input
              name="name"
              type="text"
              reference={register({ required: true })}
              nonLabel={true}
              errors={errors}
              title="Name"
              placeholder="Name *"
            />
            <Input
              name="company"
              type="text"
              reference={register({ required: false })}
              nonLabel={true}
              errors={errors}
              title="Company"
              placeholder="Company"
            />
            <Input
              name="tax_id"
              type="text"
              reference={register({ required: false })}
              nonLabel={true}
              errors={errors}
              title="Tax ident"
              placeholder="Tax ident"
            />
          </div>
          <div className="inputRow">
            <Input
              name="street"
              type="text"
              reference={register({ required: true })}
              nonLabel={true}
              errors={errors}
              title="Street *"
              placeholder="Street *"
            />
            <Input
              name="street_number"
              type="text"
              reference={register({ required: true })}
              nonLabel={true}
              errors={errors}
              title="No."
              placeholder="No. *"
            />
          </div>
          <div className="inputRow">
            <Input name="zip" type="text" reference={register({ required: true })} nonLabel={true} errors={errors} title="Zip" placeholder="Zip *" />
            <Input
              name="city"
              type="text"
              reference={register({ required: true })}
              nonLabel={true}
              errors={errors}
              title="City"
              placeholder="City *"
            />
            <Input
              name="country"
              type="text"
              reference={register({ required: true })}
              nonLabel={true}
              errors={errors}
              title="Country"
              placeholder="Country *"
            />
          </div>
          <Input
            name="phone"
            type="text"
            reference={register({ required: false })}
            nonLabel={true}
            errors={errors}
            title="Phone"
            placeholder="Phone"
          />
        </div>

        <h3>Purchase information</h3>
        <label htmlFor="terms" className={`field agree ${errors.terms && "error"}`}>
          <input name="terms" id="terms" type="checkbox" ref={register({ required: true })} />
          <strong>
            I accept the{" "}
            <a href="https://www.fygen.com/terms-and-conditions" target="_blank">
              Terms and Conditions
            </a>{" "}
            and the{" "}
            <a href="https://www.fygen.com/privacy-policy" target="_blank">
              Privacy Policy
            </a>
          </strong>
        </label>
        <div>
          <button className={`btn ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">
            {loadingButton ? "PROCESSING..." : "PURCHASE NOW"}
          </button>
          <div className="error">
            {displayError && (
              <>
                {Object.values(displayError).map((item, index) => {
                  // return <div key={index}>{item}</div>;
                })}
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

const LoginForm = () => {
  return (
    <div className="register"><CheckoutForm /></div>
  );
};

export default LoginForm;

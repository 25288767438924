import React, { useState, useEffect } from "react";
import "./Category.scss";
import News from "../../Components/News/News";
import { useApi } from "../../Hooks/useApi";
import { useAuth } from "../../Hooks/useAuth";
import ValuesOfProduction from "./Tabs/ValuesOfProduction";
import FactoryActivityLevels from "./Tabs/FactoryActivityLevels";
import GlobalTradeFlow from "./Tabs/GlobalTradeFlow";
import { Link, useParams, useHistory } from "react-router-dom";
import P404 from "../P404/P404";
import FreeVersion from "../FreeVersion/FreeVersion";
import Shop from "../Shop/Shop";

const Category = () => {
  const auth = useAuth();
  const Api = useApi();
  const history = useHistory();
  const { category, tab } = useParams();

  const [data, setData] = useState(null);
  const [factories, setFactories] = useState(null);
  const [tabSelected, setTabSelected] = useState(null);
  const [errorPage, setErrorPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [version, setVersion] = useState("free");

  useEffect(() => {
    let cancel = false;
    Api.getCategory(category, tab)
      .then((result) => {
        if (result && !cancel) {
          setLoading(false);
          setVersion(result.data.version);
          setData(result.data);
        }
      })
      .catch((e) => {
        if (!cancel) {
          setErrorPage(true);
          setLoading(false);
        }
      });
    return () => {
      cancel = true;
    };
  }, [category, auth.info]);

  useEffect(() => {
    let cancel = false;

    Api.getFactoriesByCategory(category)
      .then((result) => {
        if (result.data && !cancel) {
          setFactories(result.data);
        }
      })
      .catch((e) => setErrorPage(true));

    return () => {
      cancel = true;
    };
  }, [category, auth.info]);

  useEffect(() => {
    let cancel = false;
    if (data && data.charts && data.charts.length > 0 && category && !cancel) {
      history.replace(`/${category}/${data.charts.filter((item) => item.slug === tab).length > 0 ? tab : data.charts[0].slug}`);
      if (tab) {
        setTabSelected(tab);
      }
    }
    return () => {
      cancel = true;
    };
  }, [data, category, tab]);

  const getCategoryData = (tab) => {
    if(data.charts.find(d=>d.slug === tab).data.length === 0) {
      Api.getCategoryData(category, tab)
      .then((result) => {
        if (result) {
          const addData = data;
          addData.charts.find(d=>d.slug === tab).data = result.data;
          setData(addData);
        }
      })
    }
    setTabSelected(tab);
  }

  const shopMode = true;
  return (
    <div className="category-page container">
      {!errorPage && data ? (
        <>

          <div className="header-page">
            <h1>{data.name}</h1>
            <h2>{data.description}</h2>
            <div className="search">
              <input type="text" />
              <img src="/assets/images/icon-search.png" alt="search" />
            </div>
          </div>
          {!shopMode ? <>

          <div className="tabs">
            {data.charts.map((item, i) => {
              if (i > 2) return;
              const url = item.tab.toLowerCase().match("/") ? item.tab : `/${category}/${item.slug}`;
              return (
                <Link key={i} to={url} className={`tab${tabSelected === item.slug ? " active" : ""}`} onClick={() => setTabSelected(item.slug)}>
                  {item.tab}
                </Link>
              );
            })}
          </div>

          <div className={`content-wrapper ${version === "free" && "free-version"}`}>
            {data.charts.map((item, i) => {
              return (
                <div key={i}>
                  {tabSelected === item.slug && (
                    <>
                      {item.template === 1 && <ValuesOfProduction data={item} />}
                      {item.template === 2 && <GlobalTradeFlow data={item} />}
                      {item.template === 3 && <>{version === "pro" ? <FactoryActivityLevels data={item} list={factories} /> : <FreeVersion />}</>}
                    </>
                  )}
                </div>
              );
            })}
          </div>
          </> :
            <>
              {factories && <Shop factories={factories.factories} loading={loading} errorPage={errorPage} userData={factories.subscriptions} />}
            </>
          }
          <News category={category} />
        </>
      ) : (
        <>{!loading && <P404 />}</>
      )}
    </div>
  );
};

export default Category;

import React, { useEffect } from "react";
import "./Password.scss";
import { useAuth } from "../../Hooks/useAuth";
import ResetForm from "./ResetForm";
import ForgotForm from "./ForgotForm";

const Password = ({Page}) => {
  const auth = useAuth();

  useEffect(() => {
    auth.loginStatusChange.subscribe((data) => {
      if (data !== null) {
        document.cookie = "auth="+data+";domain=fygen.com"
        window.location.href = "/my-account";
      }
    });
  }, [auth]);

  return (
    <div className="container">

      {Page === 'resetPassword' ? <ResetForm /> : <ForgotForm /> }

      <div className="reCAPTCHA">
        This site is protected by reCAPTCHA and the Google{" "}
        <a href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>{" "}
        and{" "}
        <a href="https://policies.google.com/terms" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>{" "}
        apply.
      </div>
    </div>
  );
};

export default Password;

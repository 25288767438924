import React, { useState, useEffect } from "react";
import "./Subscription.scss";
import { useParams } from "react-router-dom";
import { useApi } from "../../Hooks/useApi";
import { subscriptionStatus } from "../../Config";
import P404 from "../P404/P404";

const Subscription = () => {
  const Api = useApi();
  const { id } = useParams();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);

  const [subscription, setSubscription] = useState(null);

  useEffect(() => {
    let cancel = false;

    Api.getSubscription(id).then((data) => {
      if (!cancel && data.data) {
        setSubscription(data.data);
        setLoading(false);
        setStatus(data.data.renewal_status);
      }
    });

    return () => {
      cancel = true
    };
  }, []);

  const handelCancelSubscription = (id) => {
    setStatus(0);
    Api.cancelSubscription(id);
  };

  return (
    <div className="container subscription-page">
      {subscription ? (
        <>
          <div className="header-page">
            <h1>
              {subscription.plan && subscription.plan.name}
              {subscription.factory && subscription.factory.name}
              </h1>
          </div>
          <div className="row">
            <div>
              <strong>Started at</strong>
              {subscription.start}
            </div>
            <div>
              <strong>End at</strong>
              {subscription.end}
            </div>
            <div>
              <strong>Status</strong>
              {subscriptionStatus[subscription.status]}
            </div>
            <div>
              <strong>Cost</strong>€{subscription.price}
            </div>
            <div>
              <strong>Renewal Date</strong>
              {status ? (
                <>
                  {subscription.end}{" "}
                  <span className="link" onClick={() => handelCancelSubscription(subscription.id)}>
                    Cancel
                  </span>
                </>
              ) : (
                "Canceled"
              )}
            </div>
            <div>
              <strong>Industries selected</strong>
              {/* {subscription.categories.length} of {subscription.plan.items_amount} */}
            </div>
          </div>
          <div className="input-wrapper">
            <h3>Select your Industries</h3>
          </div>
          <div className="subscriptions">



          <div className="table">
              <div className="table-row">
                <div>Product</div>
                <div>Cost</div>
                <div>Start Date</div>
                <div>End Date</div>
                <div>Renewal Date</div>
                <div>Status</div>
                <div>Invoices</div>
              </div>
              {subscription.subscriptions && subscription.subscriptions.length > 0 ? (
                subscription.subscriptions.map((data, index) => {
                  const invoice = subscription.invoices && subscription.invoices.filter(item=>item.number === data.invoice_number);
                  return (
                    <div className="table-row" key={index}>
                      <div>
                        {data.plan && data.plan.name}
                        {data.factory && data.factory.name}
                      </div>
                      <div>€{data.price}</div>
                      <div>{data.start}</div>
                      <div>{data.end}</div>
                      <div>{data.renewal_status ? data.end : "Canceled"}</div>
                      <div>{data.status === 0 ? "Pending" : data.status === 1 ? "Active" : "Deactivate"}</div>
                      <div>
                        {subscription.invoices && 
                          <>
                            {invoice && invoice[0] ?<a href={invoice[0].invoice_pdf}>Download</a>: "" }
                          </>
                        }
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="row">No subcription exist</div>
              )}
            </div>




          </div>
          <div>
            {/* <button className={`btn ${loadingButton ? "loading" : ""}`} disabled={loadingButton} type="submit">
                Save
              </button>
              <div className="error">{error && <>Error Message</>}</div> */}
          </div>
        </>
      ) : (
        <>{!loading && <P404 />}</>
      )}
    </div>
  );
};

export default Subscription;

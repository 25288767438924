var globalEvents = {};
export class EventEmitter<T>{
    constructor(private name: string) {
        if (globalEvents[name] === undefined) {
            globalEvents[name] = [];
        }
    }
    //events: ((x: T) => void)[] = [];
    subscribe = (callBack) => {
        globalEvents[this.name].push(callBack);
    };

    emit = (value: T) => {
        if (globalEvents[this.name].length > 0) {
            for (var i in globalEvents[this.name]) {
                if (globalEvents[this.name] && globalEvents[this.name][i]) {
                    globalEvents[this.name][i](value);
                }
            }
        }
    }
    static resetEvents(): void {
        globalEvents = {};
    }

}
import React, { useState, useEffect } from "react";
import Chart from "react-google-charts";
import * as Config from "../../Config"
import "./AppChart.scss"

const AppChart = (props) => {
  const pData = props.data;
  const all = pData;
  const [data, setData] = useState(all);
  const [ticks, setTicks] = useState(data && data.map(row => row[0]).slice(1));

  const left = "auto"
  const right = window.innerWidth > 800 ? 40 : 0;

  const formatters = [];
  for(var i=1; i<=pData[0].length; i++) {
    if(i>1){
      formatters.push({
        type: 'NumberFormat',
        column: i-1,
        options: {
          fractionDigits: 0,
          prefix: props.format,
          negativeParens: false,
        },
      })
    };
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString('default', { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }

  const tooltip = () => {
    return all.map((row, index) => {
      if (index === 0) {
        //Header
        return [...row, { role: 'tooltip', type: 'string', p: { html: true } }];
      } else {
        // Data
        const date = row[0].toString();
        const data = row[1];
        const style = "font-family:Arial;font-size:15px;color:#000000;opacity:1;margin:0;font-style:none;text-decoration:none;";
        const tooltip = `
        <ul class="google-visualization-tooltip-item-list">
          <li class="google-visualization-tooltip-item">
            <span style="${style} font-weight:bold;">Week starting at ${formatDate(date)}</span>
          </li>
          <li class="google-visualization-tooltip-item">
            <span style="${style} font-weight:none;">Visits per week: </span>
            <span style="${style} font-weight:bold;">${data}</span>
          </li>
        </ul>`;
        return [...row, tooltip];
      }
    })
  }

  useEffect(() => {
    let cancel = false;
    if(!cancel) {
      setData(tooltip());
    }
    return () => {
      cancel = true;
    }
  }, [pData])

  const minMax = props.minmax ? props.minmax : {max:1,min:-1};
  const vAxis = props.footfall ? {baselineColor:'#CB267B'} : props.traffic ? {viewWindow:minMax} : {};

  //With MinMax (max: 1, min: -1)
  // const vAxis = props.footfall ? {baselineColor:'#CB267B' ,viewWindow:minMax} : props.traffic ? {viewWindow:minMax} : {};

  return (
    <div className="chart">
      {data && <>
        <Chart
          width={"100%"}
          height={props.height}
          chartType={!props.chartType ? "LineChart" : props.chartType}
          loader={<div>Loading Chart...</div>}
          data={data}
          options={{
            tooltip: {isHtml: true},
            curveType: 'function',
            legend: { position: "none" },
            colors: props.colors ? props.colors : Config.colors,
            backgroundColor: "#1C1C23",
            candlestick: {
              fallingColor: {
                  fill: "#fff",
              }
            },



            vAxis: { format: 'decimal', direction: 1, gridlines: { color: "#8E8E91", minSpacing: 80 }, textStyle: { color: "#8E8E91" }, ...vAxis },
            hAxis: { ticks:ticks, gridlines: { color: "none" }, textStyle: { color: "#8E8E91" }, format: 'MMM d'},
            showRowNumber: true,
            crosshair: {orientation: 'vertical', trigger: 'both'},
            chartArea: {
              right: right,
              left: left,
              width: "90%",
              top: 10,
              bottom: 40,
            },
          }}
          chartPackages={props.zoom && ['corechart', 'controls']}
          controls={props.zoom && [
            {
              controlType: 'ChartRangeFilter',
              options: {
                filterColumnIndex: 0,
                ui: {
                  chartType: 'LineChart',
                  minRangeSize: 432000000,
                  chartOptions: {
                    colors: Config.colors,
                    height: 81,
                    backgroundColor: "#1C1C23",
                    chartArea: { width: '90%', height: '100%', backgroundColor: "#2a2931"},
                    hAxis: { ticks:ticks, baselineColor: 'none' , textStyle: { color: "#8E8E91" }},
                  },
                },
              },
              controlPosition: 'bottom',
            },
          ]}

          formatters={formatters}
          rootProps={{ "data-testid": "2" }}
        />
      </>
      }
    </div>
  );
};

export default AppChart;
